export default {
  data() {
    return {
      showAuthExpiredDialog: false,
      checkEsriTokenExpirationTimer: undefined,
    };
  },
  methods: {
    checkEsriTokenExpiration() {
      const esriTokenExpiration = localStorage.getItem("esri_token_expiration");
      if (isNaN(+esriTokenExpiration) || +esriTokenExpiration === 0) {
        return;
      }

      if (+new Date() > esriTokenExpiration) {
        clearInterval(this.checkEsriTokenExpirationTimer);
        this.showAuthExpiredDialog = true;
      }
    },
    checkEsriTokenExpirationPeriodically() {
      this.checkEsriTokenExpiration();
      this.checkEsriTokenExpirationTimer = setInterval(() => {
        this.checkEsriTokenExpiration();
      }, 5000);
    },
  },
  beforeDestroy() {
    clearInterval(this.checkEsriTokenExpirationTimer);
  },
};
