var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.displayAuthExpiredDialog,
        callback: function($$v) {
          _vm.displayAuthExpiredDialog = $$v
        },
        expression: "displayAuthExpiredDialog"
      }
    },
    [
      _c("v-toolbar", { attrs: { dark: "", color: "#3F51B5" } }, [
        _c(
          "div",
          {
            staticClass:
              "\n        d-flex\n        justify-space-between\n        align-center\n        px-0\n        py-1\n        ma-0\n        flex-grow-1\n      "
          },
          [
            _c("v-toolbar-title", [_vm._v(" Authorization Expired ")]),
            _c(
              "v-btn",
              {
                attrs: { text: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("auth-expired-dialog-close")
                  }
                }
              },
              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "py-3" },
            [
              _c("div", { staticStyle: { "font-size": "16px" } }, [
                _vm._v(
                  " ArcGIS authorization has expired. Sign in with ArcGIS to continue "
                )
              ]),
              _vm.showPortalUrlDialog
                ? _c("ArcGisEnterpriseLoginDialog", {
                    attrs: {
                      openOutsideLoginPage: "",
                      showPortalUrlDialog: _vm.showPortalUrlDialog
                    },
                    on: {
                      "portal-url-dialog-close": function($event) {
                        _vm.showPortalUrlDialog = false
                      },
                      "portal-login-success": _vm.onPortalLoginSuccess
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: { click: _vm.signOut }
                },
                [_vm._v("SIGN OUT")]
              ),
              _vm.getConnectionType() === "portal"
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "#3F51B5" },
                      on: {
                        click: function($event) {
                          _vm.showPortalUrlDialog = true
                        }
                      }
                    },
                    [_vm._v(" ARCGIS ENTERPRISE SIGN IN ")]
                  )
                : _vm.getConnectionType() === "agol"
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "#3F51B5" },
                      on: {
                        click: function($event) {
                          _vm.showPortalUrlDialog = true
                        }
                      }
                    },
                    [_vm._v(" ARCGIS ONLINE SIGN IN ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }