<template>
  <v-dialog v-model="displayAuthExpiredDialog" width="500" persistent>
    <v-toolbar dark color="#3F51B5">
      <div
        class="
          d-flex
          justify-space-between
          align-center
          px-0
          py-1
          ma-0
          flex-grow-1
        "
      >
        <v-toolbar-title> Authorization Expired </v-toolbar-title>
        <v-btn text @click="$emit('auth-expired-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <v-card>
      <v-card-text class="py-3">
        <div style="font-size: 16px">
          ArcGIS authorization has expired. Sign in with ArcGIS to continue
        </div>

        <ArcGisEnterpriseLoginDialog
          v-if="showPortalUrlDialog"
          openOutsideLoginPage
          :showPortalUrlDialog="showPortalUrlDialog"
          @portal-url-dialog-close="showPortalUrlDialog = false"
          @portal-login-success="onPortalLoginSuccess"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text @click="signOut" color="#3F51B5">SIGN OUT</v-btn>
        <v-btn
          text
          v-if="getConnectionType() === 'portal'"
          @click="showPortalUrlDialog = true"
          color="#3F51B5"
        >
          ARCGIS ENTERPRISE SIGN IN
        </v-btn>
        <v-btn
          text
          v-else-if="getConnectionType() === 'agol'"
          @click="showPortalUrlDialog = true"
          color="#3F51B5"
        >
          ARCGIS ONLINE SIGN IN
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from "@/mixins/utilisync-db";
import { mdiClose } from "@mdi/js";
import ArcGisEnterpriseLoginDialog from "@/components/app/ArcGisEnterpriseLoginDialog";

export default {
  name: "AuthExpiredDialog",
  props: {
    showAuthExpiredDialog: Boolean,
  },
  components: {
    ArcGisEnterpriseLoginDialog,
  },
  data() {
    return {
      displayAuthExpiredDialog: false,
      showPortalUrlDialog: false,
      mdiClose,
    };
  },
  methods: {
    onPortalLoginSuccess() {
      this.showPortalUrlDialog = false;
      this.$emit("portal-login-success");
    },
    getConnectionType() {
      let auth;
      try {
        auth = JSON.parse(localStorage.getItem("auth"));
      } catch (error) {
        console.log(error);
      }
      return auth?.organization?.gis_connection_type;
    },
    async signOut() {
      this.displayAuthExpiredDialog = false;
      await db.delete();
      const portalUrl = localStorage.getItem("portal-url");
      const filterChoices = localStorage.getItem("filter-choices");
      const layerVisibilitySettings = localStorage.getItem(
        "layer-visibility-settings"
      );
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("portal-url", portalUrl);
      localStorage.setItem("filter-choices", filterChoices);
      localStorage.setItem(
        "layer-visibility-settings",
        layerVisibilitySettings
      );
      this.showSignOutDialog = false;
      await this.$router.push({ name: "Login", query: undefined });
      this.$router.go(0);
    },
  },
  beforeMount() {
    this.displayAuthExpiredDialog = this.showAuthExpiredDialog;
  },
};
</script>
