<template>
  <div class="d-flex flex-column" style="height: 100%">
    <v-toolbar color="#3F51B5" height="56px" class="flex-grow-0">
      <v-list-item two-line class="px-0 mx-0">
        <v-list-item-content class="px-1">
          <v-list-item-title class="white--text">
            Notifications
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-btn icon text @click="$emit('close-notification-pane')">
        <v-icon color="white">
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div
      class="card-content flex-grow-1 overflow-y-auto px-5 py-2"
      v-if="notifications.length > 0"
    >
      <v-card
        v-for="n of notifications"
        :key="n.in_app_notification_id"
        class="mt-3"
      >
        <v-card-title class="d-flex justify-space-between py-1">
          <div class="d-flex">
            <div class="subtitle-1 mr-2">Utilibot Error</div>
            <v-chip
              color="#d50000"
              v-if="!n.is_read"
              label
              dark
              small
              style="margin-top: 2px"
            >
              NEW
            </v-chip>
          </div>
          <div>
            <v-btn icon @click="deleteNotification(n.in_app_notification_id)">
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pt-3">
          <div class="d-flex">
            <div class="caption notification-attribute">DATE</div>
            <div class="value">
              {{ n.created_on | formatVariableDate("hh:mm A MM/DD/YY") }}
            </div>
          </div>
          <div class="d-flex">
            <div class="caption notification-attribute">REF</div>
            <div
              class="cursor-pointer notification-value"
              @click="onRefClick(n.form_result_id)"
              v-if="getRefField(n)"
            >
              {{ getRefField(n) }}
            </div>
            <div v-else class="font-weight-medium" style="color: #4c4c4c">
              [Reference Field Not Set]
            </div>
          </div>
          <div class="d-flex">
            <div class="caption notification-attribute">UTILIBOT</div>
            <div
              class="cursor-pointer notification-value"
              @click="onUtilibotClick(n.form_result_id)"
            >
              {{ n.notification_attributes.failed_form_result_task_name }}
            </div>
          </div>
          <div class="d-flex">
            <div class="caption notification-attribute">FORM</div>
            <div
              class="cursor-pointer notification-value"
              @click="onFormResultIdClick(n.form_result_id)"
            >
              {{ n.notification_attributes.form_name }}
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-dialog
        v-model="showEditFormDialog"
        max-width="600px"
        persistent
        :fullscreen="isFullScreen"
      >
        <v-card>
          <DynamicForm
            :formDefinition="selectedFormResult"
            :existingFormResultIdMap="existingFormResultIdMap"
            :canEdit="false"
            :globalId="globalId"
            :objectId="featureId"
            :selectedMapServiceId="selectedMapServiceId"
            @ticket-edit-form-close-button-click="showEditFormDialog = false"
            @ticket-edit-form-close="showEditFormDialog = false"
            @ticket-edit-form-submitted="showEditFormDialog = false"
            v-if="showEditFormDialog"
          />
        </v-card>
      </v-dialog>

      <UtilibotDialog
        :showUtilibotDialog="showUtilibotDialog"
        :formResultId="selectedFormResultId"
        @utilibot-dialog-close="showUtilibotDialog = false"
      />
    </div>
    <div v-else class="d-flex justify-center py-3 flex-grow-1">
      No new notifications
    </div>

    <div class="flex-grow-0 d-flex justify-end" v-if="notifications.length > 0">
      <v-btn text color="#3F51B5" @click="clearAll"> Clear All </v-btn>
    </div>
  </div>
</template>

<script>
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import UtilibotDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/UtilibotDialog";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import { mdiClose } from "@mdi/js";
import { mapMutations } from "vuex";
import UNAUTHENTICATED_ROUTES from "@/constants/unauthenticatedRoutes";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";

const APIURL = process.env.VUE_APP_API_URL;
const VUE_APP_FRONT_END_URL = process.env.VUE_APP_FRONT_END_URL;

export default {
  name: "NotificationsPane",
  mixins: [fullScreenCheckMixin],
  components: {
    UtilibotDialog,
    DynamicForm,
  },
  computed: {
    gisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (!this.attributes || !Array.isArray(this.fields)) {
          return {};
        }

        const entries = this.fields.map(({ name: key }) => {
          const value = this.attributes[key];

          return [this.findAliasByName(this.fields, key), value];
        });
        return Object.fromEntries(entries);
      } else {
        const entries = this.gisDataFieldsAndValues.map((fv) => {
          const { name, value } = fv;
          return [name, value];
        });
        return Object.fromEntries(entries);
      }
    },
    notUtiliSyncLayer() {
      return this.selectedMapService.service_type !== "U";
    },
    title() {
      return this.selectedMapService.service_name;
    },
    subtitle() {
      if (this.notUtiliSyncLayer) {
        return this.attributes[this.selectedMapService.ref_field];
      }
      const { ref_field: refField } = this.selectedMapService;
      return (
        this.gisDataFieldsAndValues.find((f) => f.name === refField)?.value ??
        "[Not Provided]"
      );
    },
    alreadySubmittedFinalOnline() {
      const { form: { status } = {} } = this.selectedFormResult ?? {};
      return status === "SUBMITTED_FINAL";
    },
  },
  data() {
    return {
      notifications: [],
      showEditFormDialog: false,
      showUtilibotDialog: false,
      selectedFormResult: {},
      selectedFormResultId: "",
      selectedLocateRequestId: "",
      selectedLocateRequestNumber: "",
      selectedMapServiceId: "",
      showTicketDrawer: false,
      formDefinition: {},
      existingFormResultIdMap: {},
      mdiClose,
      globalId: undefined,
      featureId: undefined,
      attributes: {},
      fields: [],
      selectedMapService: {},
      gisDataFieldsAndValues: [],
      gisDataValues: [],
      gisDataFields: [],
      mapServices: [],
    };
  },
  methods: {
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
    async clearAll() {
      await Promise.all(
        this.notifications.map((n) =>
          axiosWithRegularAuth.delete(
            `${APIURL}/notification_in_app/one/${n.in_app_notification_id}`
          )
        )
      );
      await this.getNotifications();
    },
    async deleteNotification(notificationId) {
      await axiosWithRegularAuth.delete(
        `${APIURL}/notification_in_app/one/${notificationId}`
      );
      await this.getNotifications();
    },
    async getNotifications() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/notification_in_app`);
      this.notifications = results.filter(
        (r) =>
          r.notification_type === "failed_form_result_task" &&
          !r.feature_attributes.locate_request_id
      );
    },
    async checkNotifications() {
      if (
        UNAUTHENTICATED_ROUTES.includes(this.$route.name) ||
        !!navigator.onLine
      ) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/notification_in_app/is_new_notification`
      );
      this.setIsNewNotifications(results);
    },
    async onFormResultIdClick(formResultId) {
      const { data: selectedFormResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      this.selectedFormResult = selectedFormResult;
      const {
        feature_attributes: featureAttributes = {},
        map_service_id: selectedMapServiceId,
      } = selectedFormResult ?? {};
      const { OBJECTID: objectId } = featureAttributes;
      this.selectedMapServiceId = selectedMapServiceId;

      const {
        data: { results: selectedMapService },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/map_services/${selectedMapServiceId}`
      );
      if (selectedMapService.service_type === "F") {
        const mapServiceUrl = selectedMapService.service_url;
        const { data: queryResult } = await axiosWithNoAuth.get(
          `${mapServiceUrl}/query`,
          {
            params: {
              objectids: objectId,
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          }
        );
        const { features, globalIdFieldName } = queryResult;
        const [{ attributes }] = features;
        this.globalId = attributes[globalIdFieldName];
        this.featureId = objectId;
      } else {
        const {
          data: { results: gisDataPoint },
        } = await axiosWithRegularAuth.get(`${APIURL}/gis_data_point`, {
          params: {
            map_service_id: selectedMapServiceId,
            feature_id: objectId,
          },
        });
        this.objectId = gisDataPoint.gis_data_point_id;
        this.featureId = +objectId;
      }
      this.existingFormResultIdMap = {
        formResultId,
      };
      this.showEditFormDialog = true;
    },
    async onUtilibotClick(formResultId) {
      this.showUtilibotDialog = true;
      this.selectedFormResultId = formResultId;
    },
    async onRefClick(formResultId) {
      const { data: selectedFormResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      this.selectedFormResult = selectedFormResult;
      const {
        feature_attributes: featureAttributes = {},
        map_service_id: selectedMapServiceId,
      } = selectedFormResult ?? {};
      const { OBJECTID: objectId } = featureAttributes;
      this.selectedMapServiceId = selectedMapServiceId;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/map_services/${this.selectedMapServiceId}`
      );
      this.selectedMapService = results;
      if (this.selectedMapService.service_type === "F") {
        const mapServiceUrl = results.service_url;
        const { data: queryResult } = await axiosWithNoAuth.get(
          `${mapServiceUrl}/query`,
          {
            params: {
              objectids: objectId,
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          }
        );
        const { features, objectIdFieldName, globalIdFieldName } = queryResult;
        const [{ attributes }] = features;
        this.globalId = attributes[globalIdFieldName];
        this.featureId = attributes[objectIdFieldName];
      } else {
        const {
          data: { results: gisDataPoint },
        } = await axiosWithRegularAuth.get(`${APIURL}/gis_data_point`, {
          params: {
            map_service_id: this.selectedMapServiceId,
            feature_id: objectId,
          },
        });
        this.globalId = gisDataPoint.gis_data_point_id;
        this.featureId = objectId;
      }
      window.open(
        `${VUE_APP_FRONT_END_URL}/site?layer=${this.selectedMapServiceId}&global_id=${this.globalId}&feature_id=${this.featureId}`,
        "blank"
      );
    },
    getRefField(notification) {
      return notification?.notification_attributes?.ref_field;
    },
    ...mapMutations(["setIsNewNotifications"]),
  },
  async beforeMount() {
    await this.getNotifications();
    await this.checkNotifications();
  },
};
</script>

<style scoped>
.notification-attribute {
  width: 70px;
  max-width: 70px;
  font-size: 12px;
}

.notification-value,
.value {
  width: calc(100% - 70px);
  max-width: calc(100% - 70px);
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-value {
  color: #00a9f4;
}

.card-content {
  background-color: #fafafa;
}

#ticket-log {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#ticket-log >>> .v-expansion-panel-content__wrap {
  padding-bottom: 0;
}
</style>
