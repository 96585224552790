var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column", staticStyle: { height: "100%" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "flex-grow-0",
          attrs: { color: "#3F51B5", height: "56px" }
        },
        [
          _c(
            "v-list-item",
            { staticClass: "px-0 mx-0", attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-content",
                { staticClass: "px-1" },
                [
                  _c("v-list-item-title", { staticClass: "white--text" }, [
                    _vm._v(" Notifications ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close-notification-pane")
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "white" } }, [
                _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.notifications.length > 0
        ? _c(
            "div",
            {
              staticClass: "card-content flex-grow-1 overflow-y-auto px-5 py-2"
            },
            [
              _vm._l(_vm.notifications, function(n) {
                return _c(
                  "v-card",
                  { key: n.in_app_notification_id, staticClass: "mt-3" },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "d-flex justify-space-between py-1" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("div", { staticClass: "subtitle-1 mr-2" }, [
                              _vm._v("Utilibot Error")
                            ]),
                            !n.is_read
                              ? _c(
                                  "v-chip",
                                  {
                                    staticStyle: { "margin-top": "2px" },
                                    attrs: {
                                      color: "#d50000",
                                      label: "",
                                      dark: "",
                                      small: ""
                                    }
                                  },
                                  [_vm._v(" NEW ")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteNotification(
                                      n.in_app_notification_id
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("v-card-text", { staticClass: "pt-3" }, [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "caption notification-attribute" },
                          [_vm._v("DATE")]
                        ),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatVariableDate")(
                                  n.created_on,
                                  "hh:mm A MM/DD/YY"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "caption notification-attribute" },
                          [_vm._v("REF")]
                        ),
                        _vm.getRefField(n)
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer notification-value",
                                on: {
                                  click: function($event) {
                                    return _vm.onRefClick(n.form_result_id)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.getRefField(n)) + " ")]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "font-weight-medium",
                                staticStyle: { color: "#4c4c4c" }
                              },
                              [_vm._v(" [Reference Field Not Set] ")]
                            )
                      ]),
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "caption notification-attribute" },
                          [_vm._v("UTILIBOT")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "cursor-pointer notification-value",
                            on: {
                              click: function($event) {
                                return _vm.onUtilibotClick(n.form_result_id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  n.notification_attributes
                                    .failed_form_result_task_name
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "caption notification-attribute" },
                          [_vm._v("FORM")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "cursor-pointer notification-value",
                            on: {
                              click: function($event) {
                                return _vm.onFormResultIdClick(n.form_result_id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(n.notification_attributes.form_name) +
                                " "
                            )
                          ]
                        )
                      ])
                    ])
                  ],
                  1
                )
              }),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600px",
                    persistent: "",
                    fullscreen: _vm.isFullScreen
                  },
                  model: {
                    value: _vm.showEditFormDialog,
                    callback: function($$v) {
                      _vm.showEditFormDialog = $$v
                    },
                    expression: "showEditFormDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _vm.showEditFormDialog
                        ? _c("DynamicForm", {
                            attrs: {
                              formDefinition: _vm.selectedFormResult,
                              existingFormResultIdMap:
                                _vm.existingFormResultIdMap,
                              canEdit: false,
                              globalId: _vm.globalId,
                              objectId: _vm.featureId,
                              selectedMapServiceId: _vm.selectedMapServiceId
                            },
                            on: {
                              "ticket-edit-form-close-button-click": function(
                                $event
                              ) {
                                _vm.showEditFormDialog = false
                              },
                              "ticket-edit-form-close": function($event) {
                                _vm.showEditFormDialog = false
                              },
                              "ticket-edit-form-submitted": function($event) {
                                _vm.showEditFormDialog = false
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("UtilibotDialog", {
                attrs: {
                  showUtilibotDialog: _vm.showUtilibotDialog,
                  formResultId: _vm.selectedFormResultId
                },
                on: {
                  "utilibot-dialog-close": function($event) {
                    _vm.showUtilibotDialog = false
                  }
                }
              })
            ],
            2
          )
        : _c("div", { staticClass: "d-flex justify-center py-3 flex-grow-1" }, [
            _vm._v(" No new notifications ")
          ]),
      _vm.notifications.length > 0
        ? _c(
            "div",
            { staticClass: "flex-grow-0 d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: { click: _vm.clearAll }
                },
                [_vm._v(" Clear All ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }